import { useCallback, useMemo, useState } from 'react'

import type { CustomFilterShape } from '../CustomFilter'
import { useQueryState } from '../QueryState/useQueryState'
import type { OperatorAndValue } from '../TableFilters'
import {
	handleColumnVisibilityChanges,
	handleFilterChanges,
	handleGroupByChanges,
	handleSortChanges,
	parseBoolean,
	removeEmptyOperatorAndValueOverrides,
	removeEmptyOverrides,
	removeStringOverride,
} from './helpers'

const useViews = <
	T extends CustomFilterShape = CustomFilterShape,
	Q extends OperatorAndValue = OperatorAndValue,
	S extends Record<string, boolean> = Record<string, boolean>,
>(initCols?: S) => {
	const [filters, setFilters] = useQueryState<T>('filters')
	const [groupBy, setGroupBy] = useQueryState<Q>('groupBy')
	const [sort, setSort] = useQueryState<string>('sort')
	const [columnVisibility, setColumnVisibility] = useQueryState<S>(
		'columnVisibility', initCols
	)
	const [columnOrder, setColumnOrder] = useQueryState<string[]>('columnOrder')

	//TODO: consider moving these to a useReducer
	const [viewFilters, setViewFilters] = useState<T>({} as T)
	const [viewColumnVisibility, setViewColumnVisibility] = useState<S>({} as S)
	const [viewSort, setViewSort] = useState<string>()
	const [viewGroupBy, setViewGroupBy] = useState<Q>()
	const [viewColumnOrder, setViewColumnOrder] = useState<string[]>()

	const combinedFilters: CustomFilterShape = useMemo(
		() =>
			removeEmptyOverrides({
				...viewFilters,
				...filters,
			}),
		[viewFilters, filters]
	)
	const combinedGroupBy: OperatorAndValue = useMemo(
		() =>
			removeEmptyOperatorAndValueOverrides({
				...viewGroupBy,
				...groupBy,
			} as OperatorAndValue),
		[viewGroupBy, groupBy]
	)
	const combinedSort = useMemo(
		() => removeStringOverride(sort || viewSort),
		[sort, viewSort]
	)
	const combinedColumnVisibility: Record<string, boolean> = useMemo(
		() =>
			parseBoolean({
				...viewColumnVisibility,
				...columnVisibility,
			}),
		[viewColumnVisibility, columnVisibility]
	)

	const combinedColumnOrder: string[] = useMemo(
		() => (columnOrder?.length > 0 ? columnOrder : viewColumnOrder),
		[columnOrder, viewColumnOrder]
	)

	const onFiltersChange = useCallback(
		(newValues: CustomFilterShape) => {
			const cleanOverrides = handleFilterChanges(
				newValues,
				viewFilters
			) as T

			setFilters(cleanOverrides)
		},
		[setFilters, viewFilters]
	)

	const onGroupByChange = useCallback(
		(newValues: OperatorAndValue) => {
			const cleanOverrides = handleGroupByChanges(
				newValues,
				viewGroupBy
			) as Q

			setGroupBy(cleanOverrides)
		},
		[setGroupBy, viewGroupBy]
	)

	const onColumnVisibilityChange = useCallback(
		(newValues: Record<string, boolean>) => {
			const values = handleColumnVisibilityChanges(
				newValues,
				viewColumnVisibility
			) as S

			setColumnVisibility(values)
		},
		[setColumnVisibility, viewColumnVisibility]
	)

	const onColumnOrderChange = useCallback(
		(newValues: string[]) => {
			if (
				viewColumnOrder &&
				newValues.toString() === viewColumnOrder.toString()
			) {
				setColumnOrder([])
				return
			}

			setColumnOrder(newValues)
		},
		[setColumnOrder, viewColumnOrder]
	)

	const onSortChange = useCallback(
		(newValue: string) => {
			const cleanOverrides = handleSortChanges(newValue, viewSort)
			setSort(cleanOverrides)
		},
		[setSort, viewSort]
	)

	const resetUrlParams = useCallback(() => {
		{
			setFilters({} as T)
			setGroupBy(null)
			setColumnVisibility({} as S)
			setSort(null)
			setColumnOrder([])
		}
	}, [setFilters, setGroupBy, setColumnVisibility, setSort, setColumnOrder])

	return {
		URLFilters: filters,
		filters: combinedFilters,
		setFilters: onFiltersChange,
		setViewFilters,
		URLGroupBy: groupBy,
		groupBy: combinedGroupBy,
		setGroupBy: onGroupByChange,
		setViewGroupBy,
		URLSort: sort,
		sort: combinedSort,
		setSort: onSortChange,
		setViewSort,
		URLColumnVisibility: columnVisibility,
		columnVisibility: combinedColumnVisibility,
		setColumnVisibility: onColumnVisibilityChange,
		URLColumnOrder: columnOrder,
		columnOrder: combinedColumnOrder,
		setColumnOrder: onColumnOrderChange,
		setViewColumnOrder,
		setViewColumnVisibility,
		resetUrlParams,
	}
}

export default useViews
