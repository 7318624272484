/* eslint-disable no-unused-vars */
import { memo } from 'react'

import { Box, MenuItem, Stack } from '@mui/material'
import type { Row } from '@tanstack/react-table'
import type { AssetTableEntryDTO, Flow } from 'api/repository/repositorySchemas'
import AstaKebabMenu from 'components/shared/AstaKebabMenu'
import type { IUser } from 'deprecated/types/interfaces'
import {
	canEdit as _canEdit,
	canRemove as _canRemove,
} from 'pages/Admin/helpers'
import type { FC } from 'react'
import type { Asset, AssetWithParent } from 'shared/types'

import DuplicateAssetMenu from './DuplicateAssetMenu'
import MoveAssetMenu from './MoveAssetMenu'
import RunFlowAction from './RunFlowAction'

interface Props {
	row: Row<AssetTableEntryDTO>
	user: IUser
	rowOnClick: (asset: AssetWithParent) => void
	onStatusChange: (asset: AssetWithParent) => void
	deleteAsset: (asset: AssetWithParent) => void
	type: Asset['type']
}

const ActionsCell: FC<Props> = ({
	row,
	user,
	rowOnClick,
	onStatusChange,
	type,
	deleteAsset,
}) => {
	const isGroup = row.original.isGroup

	if (isGroup) return null

	const {
		asset: {
			parent: { role: userRoleOnAsset },
		},
	} = row.original
	const canEdit = _canEdit(userRoleOnAsset) || user.isAdmin
	const canRemove = _canRemove(userRoleOnAsset) || user.isAdmin
	const canPerformAction = canEdit || canRemove || user.isAdmin

	const isFlow = row.original.asset.type === 'flow'
	const isActive = row.original.asset.status === 'active'
	if (!canPerformAction && !isFlow && !isActive) return null

	return (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
		>
			<AstaKebabMenu>
				{({ onClick }) => (
					<Box>
						{isFlow && isActive && (
							<RunFlowAction flow={row.original.asset as Flow} />
						)}
						{canEdit && (
							<MenuItem
								onClick={() => (
									rowOnClick &&
										rowOnClick(row.original.asset),
									onClick()
								)}
							>
								Edit
							</MenuItem>
						)}
						{row.original.asset.status !== 'invalid' && canEdit && (
							<MenuItem
								onClick={() => (
									onStatusChange(row.original.asset),
									onClick()
								)}
							>
								{row.original.asset.status === 'active'
									? 'Deactivate'
									: 'Activate'}
							</MenuItem>
						)}
						{canRemove && (
							<MenuItem
								onClick={() => (
									deleteAsset(row.original.asset), onClick()
								)}
							>
								Delete
							</MenuItem>
						)}
						<DuplicateAssetMenu
							type={type}
							asset={row.original.asset}
							onClose={onClick}
						/>
						<MoveAssetMenu
							type={type}
							asset={row.original.asset}
							onClose={onClick}
						/>
					</Box>
				)}
			</AstaKebabMenu>
		</Stack>
	)
}

export const AssetActions = memo(ActionsCell)
