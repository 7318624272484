import React, { useCallback } from 'react'

import { MenuItem } from '@mui/material'
import { QueryClient } from '@tanstack/react-query'
import { useStartRunControllerStartRun } from 'api/repository/repositoryComponents'
import type { Flow } from 'api/repository/repositorySchemas'
import { useSelectedApp } from 'contexts/selectedApp'
import type { FC } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

interface RunFlowActionProps {
	flow: Flow
}

const RunFlowAction: FC<RunFlowActionProps> = ({ flow }) => {
	const navigate = useNavigate()
	const queryClient = new QueryClient()
	const startRun = useStartRunControllerStartRun()
	const { selectedApp: app } = useSelectedApp()

	const onFlowRun = useCallback(async () => {
		const loadingToastId = toast.loading('Starting run...')

		startRun.mutate(
			{
				pathParams: {
					id: app._id,
				},
				body: {
					parameters: {
						name: 'Custom Flow Run',
						actionRetryAttempts: 1,
						pageLoadTimeout: 3000,
						_id: null,
						path: '/',
						depth: 1,
						duration: 15,
						stopAfterFlows: true,
						stopOnFlowError: true,
						enableModeling: false,
						useDatasetsForForms: false,
						assets: {
							datasets: {},
							rules: [],
							data: [],
							activities: [],
						},
						testableDomains: [],
						extensions: {
							accessibility: false,
							brokenLinks: false,
							resources: false,
							performance: false,
							functional: false,
						},
						workQueue: [
							{
								id: uuid(),
								type: 'Flow',
								state: 'Todo',
								activityId: flow._id,
								label: flow.name,
							},
						],
					},
				},
			},
			{
				onSuccess: () => {
					toast.dismiss(loadingToastId)
					queryClient.invalidateQueries()
					toast.success(`Run started`)
					navigate(`/app/${app?._id}/status`)
				},
				onError: e => {
					toast.dismiss(loadingToastId)
					toast.error(`Failed to start run.\n ${e?.message || e}`)
				},
			}
		)
	}, [app, startRun, navigate, queryClient, flow])

	return <MenuItem onClick={onFlowRun}>Run</MenuItem>
}

export default RunFlowAction
