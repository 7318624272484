import 'shepherd.js/dist/css/shepherd.css'

import type { IssueReporterConfig } from '@asta/issue-reporter'
import {
	IssueReporterPackage,
	IssueReporterProvider,
} from '@asta/issue-reporter'
import {
	ColorSchemeProvider,
	ErrorBoundary,
	ThemeCustomizationWrapper,
} from '@asta/react-component-library'
import Snackbar from 'components/@extended/Snackbar'
import { AuthProvider } from 'contexts/auth'
import { BreadcrumbsProvider } from 'contexts/breadcrumbs'
import { SelectedAppProvider } from 'contexts/selectedApp'
import { WorkspaceProvider } from 'contexts/workspace'
import RTLLayout from 'deprecated/components/RTLLayout'
import ScrollTop from 'deprecated/components/ScrollTop'
import ErrorPage from 'pages/ErrorPage'
import { Toaster } from 'react-hot-toast'
import Routes from 'routes'
import ThemeCustomization from 'themes'

const issueReporterConfig: IssueReporterConfig = {
	organizationName: process.env.ORGANIZATION_NAME || '',
	repositoryName: process.env.REPOSITORY_NAME || '',
	'x-api-key': process.env.X_API_KEY || '', //TODO: Check this
	appId: process.env.APP_ID || '',
}

const App = (): JSX.Element => {
	return (
		<ColorSchemeProvider>
			<RTLLayout>
				<ThemeCustomization>
					<ThemeCustomizationWrapper>
						<AuthProvider>
							<WorkspaceProvider>
								<SelectedAppProvider>
									<IssueReporterProvider>
										<ScrollTop>
											<BreadcrumbsProvider>
												<ErrorBoundary
													Fallback={ErrorPage}
												>
													<Toaster />
													<Routes />
													<Snackbar />
													<IssueReporterPackage
														issueReporterConfig={
															issueReporterConfig
														}
													/>
												</ErrorBoundary>
											</BreadcrumbsProvider>
										</ScrollTop>
									</IssueReporterProvider>
								</SelectedAppProvider>
							</WorkspaceProvider>
						</AuthProvider>
					</ThemeCustomizationWrapper>
				</ThemeCustomization>
			</RTLLayout>
		</ColorSchemeProvider>
	)
}

export default App
